import React, { useRef, useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const TimeSaveChart = ({ segments, bestSegmentTimes, averageSegmentTimes }) => {
  const [chartWidth, setChartWidth] = useState('100%');
  const containerRef = useRef(null);

  const data = segments.map(segment => ({
    name: segment.name,
    PBtoGold: Math.max(segment.timeInSeconds - bestSegmentTimes[segment.name], 0),
    AvgtoGold: Math.max(averageSegmentTimes[segment.name] - bestSegmentTimes[segment.name], 0)
  }));

  useEffect(() => {
    const updateChartWidth = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const isMobile = window.innerWidth <= 768; // Adjust this breakpoint as needed
        const minWidth = isMobile ? data.length * 80 : containerWidth;
        const calculatedWidth = Math.max(minWidth, 600); // Ensure a minimum width of 600px
        setChartWidth(`${calculatedWidth}px`);
      }
    };

    updateChartWidth();
    window.addEventListener('resize', updateChartWidth);
    return () => window.removeEventListener('resize', updateChartWidth);
  }, [data]);

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
  
    const formattedTime = `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    return hours > 0 ? `${hours.toString().padStart(2, '0')}:${formattedTime}` : formattedTime;
  };

  return (
    <div className="mb-4">
      <h2 className="mb-3">Potential Time Saves</h2>
      <div ref={containerRef} className="w-full overflow-x-auto">
        <div style={{ width: chartWidth, minHeight: '400px' }}>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <XAxis dataKey="name" angle={-45} textAnchor="end" interval={0} height={100} />
              <YAxis tickFormatter={formatTime} />
              <Tooltip formatter={(value) => formatTime(value)} />
              <Legend />
              <Bar dataKey="PBtoGold" name="PB to Gold" fill="#8884d8" />
              <Bar dataKey="AvgtoGold" name="Average to Gold" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default TimeSaveChart;