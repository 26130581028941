import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { formatTime, formatDiff, COLORS } from './utils/utils';

const truncateName = (name, maxLength) => {
  if (name.startsWith('-')) {
    name = name.substring(1).trim();
  }
  if (name.length > maxLength) {
    const allowedLength = maxLength - 3;
    return name.substring(0, allowedLength) + '...';
  }
  return name;
};

const Popup = ({ segment, bestSegmentTimes, previousBestTime, position, cumulativeSplit, comparisonTime }) => {
  const currentTime = segment.timeInSeconds;
  const bestTime = bestSegmentTimes[segment.name];
  const previousTime = previousBestTime;

  const diffBest = currentTime - bestTime;
  const diffPrevious = currentTime - previousTime;

  const diffBestColor = diffBest > 0 ? 'text-danger' : 'text-success';
  const diffPreviousColor = diffPrevious > 0 ? 'text-danger' : 'text-success';

  return ReactDOM.createPortal(
    <div
      className="runviz-popup bg-white p-3 rounded shadow-lg position-fixed border border-secondary"
      style={{
        ...position,
        zIndex: 1050,
        width: '250px',
        maxWidth: '100%',
      }}
    >
      <h6 className="mb-2 text-primary">{segment.name}</h6>
      <div className="mb-1">
        <strong>Time:</strong> {formatTime(currentTime)}
      </div>
      <div className="mb-1">
        <strong>Finished at:</strong> {formatTime(cumulativeSplit)}
      </div>
      <div className="mb-1">
        <strong>Best Time:</strong> {formatTime(bestTime)}
        <span className={`ms-2 ${diffBestColor}`}>({formatDiff(diffBest)})</span>
      </div>
      <div>
        <strong>Previous PB:</strong> {formatTime(previousTime)}
        <span className={`ms-2 ${diffPreviousColor}`}>({formatDiff(diffPrevious)})</span>
      </div>
      {comparisonTime && (
        <div>
          <strong>Comparison Time:</strong> {formatTime(comparisonTime)}
        </div>
      )}
    </div>,
    document.body
  );
};

const RunVisualization = ({ segments, totalTime, bestSegmentTimes, previousBestRun, onSegmentClick, comparisonRun, comparisonDirection }) => {

  const [hoveredSegment, setHoveredSegment] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [clickAllowed, setClickAllowed] = useState(true);

  // Calculate adjusted segments based on comparisonRun
  const adjustedSegments = () => {
    if (!comparisonRun) return segments;
    if (comparisonRun.length !== segments.length) return segments;

    const adjusted = [];
    let cumulativeTime = 0;
    let comparisonCumulativeTime = 0;

    segments.forEach((segment, index) => {
      const segmentTime = segment.timeInSeconds;
      const comparisonTime = comparisonRun[index]?.timeInSeconds || segmentTime;
      const diffTime = comparisonDirection === 'main' ? comparisonTime - segmentTime : segmentTime - comparisonTime;

      adjusted.push({ ...segment, originalIndex: index });

      if (comparisonDirection !== 'main' && comparisonTime > segmentTime) {
        adjusted.push({
          name: 'Lost',
          timeInSeconds: Math.abs(diffTime),
          isEmpty: true,
          originalIndex: index,
          isFaster: true, // Add a flag to indicate faster comparison time
        });
    } 
    
    if (comparisonDirection === 'main' && comparisonTime > segmentTime) {
        // Add green segment for slower comparison time
        adjusted.push({
          name: 'Saved',
          timeInSeconds: Math.abs(diffTime), // Use the absolute difference
          isEmpty: true,
          originalIndex: index,
          isFaster: false, // Add a flag to indicate slower comparison time
        });
      }
      cumulativeTime += segmentTime;
      comparisonCumulativeTime += comparisonTime;
    });

    return adjusted;
  };

  const handleMouseEnter = (segment, event) => {
    const containerRect = containerRef.current.getBoundingClientRect();
    const segmentRect = event.currentTarget.getBoundingClientRect();
    const leftPosition = segmentRect.left + (segmentRect.width / 2);

    setHoveredSegment(segment);
    setPopupPosition({
      top: containerRect.bottom + 5,
      left: leftPosition,
      transform: 'translate(-50%, 0%)'
    });
  };

  const updateGradientVisibility = () => {
    if (containerRef.current && contentRef.current) {
      const { scrollLeft, clientWidth } = containerRef.current;
      const contentWidth = contentRef.current.scrollWidth;

      const leftGradient = document.querySelector('.left-gradient');
      const rightGradient = document.querySelector('.right-gradient');

      const maxScrollLeft = contentWidth - clientWidth;

      if (leftGradient) {
        const leftOpacity = Math.min(scrollLeft / 100, 1);
        leftGradient.style.opacity = leftOpacity.toFixed(2);
      }

      if (rightGradient) {
        const rightOpacity = Math.min((maxScrollLeft - scrollLeft) / 100, 1);
        rightGradient.style.opacity = rightOpacity.toFixed(2);
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;

    if (container && content) {
      const resizeObserver = new ResizeObserver(() => {
        setTimeout(updateGradientVisibility, 1100); // Adjust based on transition
      });

      resizeObserver.observe(content);

      const handleScroll = () => {
        updateGradientVisibility();
      };

      container.addEventListener('scroll', handleScroll);
      updateGradientVisibility(); // Initial update

      return () => {
        resizeObserver.disconnect();
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const getCumulativeSplit = (index) => {
    //console.log(index);
    //return adjustedSegments().slice(0, index + 1).splitTimeInSeconds;
    return adjustedSegments().slice(0, index + 1).reduce((acc, segment) => acc + segment.timeInSeconds, 0);
  };

  const getCumulativeSplitStartVideo = (index) => {
    return adjustedSegments().slice(0, index).reduce((acc, segment) => acc + segment.realTimeInSeconds, 0);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setClickAllowed(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };

  const handleMouseUp = (e) => {
    setIsDragging(false);
    if (clickAllowed) {
      e.preventDefault();
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    setClickAllowed(false);
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 1;
    containerRef.current.scrollLeft = scrollLeft - walk;
    updateGradientVisibility();
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setClickAllowed(true);
    setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleTouchEnd = (e) => {
    setIsDragging(false);
    if (clickAllowed) {
      e.preventDefault();
    }
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    setClickAllowed(false);
    const x = e.touches[0].pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 1;
    containerRef.current.scrollLeft = scrollLeft - walk;
    updateGradientVisibility();
  };

  const handleClick = (index) => {
    if (clickAllowed) {
      onSegmentClick(getCumulativeSplitStartVideo(index));
    }
  };

  // Calculate total width based on segments only
  const contentWidth = comparisonRun && comparisonDirection !== 'main' ? `${Math.max(comparisonRun.length * 100, 100)}px` : `${Math.max(segments.length * 100, 100)}px`;

  return (
    <div className="position-relative">
      <div
        className="position-relative overflow-hidden"
        ref={containerRef}
        style={{
          maxWidth: '100%',
          cursor: isDragging ? 'grabbing' : 'grab',
          userSelect: 'none',
        }}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        <div ref={contentRef} className="d-flex" style={{ minWidth: contentWidth, height: '100px', alignItems: 'center' }}>
          {adjustedSegments().map((segment, index) => {
            const originalIndex = segment.originalIndex ?? index; // Use original index if available
            // const previousBestSegment = previousBestRun.find(prevSegment => prevSegment.name === segment.name);
            const previousBestSegment = previousBestRun[originalIndex];
            const previousBestTime = previousBestSegment ? previousBestSegment.timeInSeconds : 0;

            const isGolden = segment.timeInSeconds <= bestSegmentTimes[segment.name];
            const diff = segment.timeInSeconds - previousBestTime;
            const diffColor = isGolden
              ? 'badge bg-dark fw-bold text-warning mt-1'
              : diff > 0
                ? 'badge bg-dark fw-bold text-danger mt-1'
                : 'badge bg-dark fw-bold text-success mt-1';

            const isHovered = hoveredSegment === segment;

            const borderRadiusStyle = index === 0
              ? { borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }
              : index === adjustedSegments().length - 1
                ? { borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }
                : {};

            return (
              <div
                key={index}
                className={`runviz-box d-flex flex-column align-items-center justify-content-center text-center ${segment.isEmpty ? 'empty-segment' : ''}`}
                style={{
                    backgroundColor: segment.isEmpty
                    ? segment.isFaster // Check the isFaster flag
                      ? 'rgba(0, 255, 0, 0.25)' // Red for faster
                      : 'rgba(0, 255, 0, 0.25)' // Green for slower
                    : COLORS[originalIndex % COLORS.length],
                  width: `${(segment.timeInSeconds / totalTime) * 100}%`,
                  height: segment.isEmpty ? '90%' : '100%',
                  position: 'relative',
                  cursor: isDragging ? 'grabbing' : 'grab',
                  transition: 'opacity 0.3s',
                  zIndex: isHovered ? 10 : 'auto',
                  boxShadow: isHovered ? '0 4px 8px rgba(0, 0, 0, 0.5)' : 'none',
                  ...borderRadiusStyle,
                }}
                onMouseEnter={(e) => handleMouseEnter(segment, e)}
                onMouseLeave={() => setHoveredSegment(null)}
                onClick={() => handleClick(index)}
              >
                {!segment.isEmpty && (
                  <>
                    <span className="text-white z-1" style={{ wordWrap: 'break-word' }}>{truncateName(segment.name, 10)}</span>
                    <span className="text-white z-1">{formatTime(segment.timeInSeconds)}</span>
                    {previousBestRun && <span className={`z-1 ${diffColor}`} style={{ fontSize: '0.8em' }}>{formatDiff(diff)}</span>}
                  </>
                )}
              </div>
            );
          })}
        </div>
        {hoveredSegment && (
          <Popup
            segment={hoveredSegment}
            bestSegmentTimes={bestSegmentTimes}
            previousBestTime={previousBestRun.find(prevSegment => prevSegment.name === hoveredSegment.name)?.timeInSeconds || 0}
            position={popupPosition}
            cumulativeSplit={getCumulativeSplit(adjustedSegments().findIndex(seg => seg === hoveredSegment))}
            comparisonTime={comparisonRun?.find(seg => seg.name === hoveredSegment.name)?.timeInSeconds || null}
          />
        )}
      </div>

      <div className="left-gradient gradient-fade" style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '40px', pointerEvents: 'none' }}></div>
      <div className="right-gradient gradient-fade" style={{ position: 'absolute', top: 0, right: 0, height: '100%', width: '40px', pointerEvents: 'none' }}></div>
    </div>
  );
};

export default RunVisualization;
