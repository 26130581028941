import { formatTime, formatDiff, getDiffColor, parseTime } from './utils/utils';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip as RechartTooltip, BarChart, Bar, Cell } from 'recharts';

const SegmentDetail = ({ segment, averageSegmentTimes, bestSegmentTimes, attempts, segments }) => {
  if (!segment) return null;

  const avgTime = averageSegmentTimes[segment.name] || 0;
  const bestTime = bestSegmentTimes[segment.name] || 0;
  const pbTime = segment.timeInSeconds || 0;

  // Calculate reset statistics
  const segmentIndex = segments.findIndex(s => s.name === segment.name);
  const totalAttempts = attempts.length;
  const attemptsReachedSegment = segmentIndex === 0 
    ? totalAttempts 
    : attempts.filter(a => {
        const segmentHistory = segments[segmentIndex - 1].segmentHistory.find(h => Number(h.id) === Number(a.id));
        return segmentHistory && (segmentHistory.realTime || segmentHistory.gameTime);
      }).length;
  
  // Get the minimum ID from attemptsReachedSegment
  const minReachedSegmentID = segmentIndex === 0 
    ? Math.min(...attempts.map(a => Number(a.id))) 
    : Math.min(...attempts
      .filter(a => {
        const segmentHistory = segments[segmentIndex - 1].segmentHistory.find(h => Number(h.id) === Number(a.id));
        return segmentHistory && (segmentHistory.realTime || segmentHistory.gameTime);
      })
      .map(a => Number(a.id)));
  
  // Filter attemptsSegment considering the minimum ID from attemptsReachedSegment
  const attemptsSegment = attempts.filter(a => {
      const segmentHistory = segments[segmentIndex].segmentHistory.find(h => Number(h.id) === Number(a.id));
      return segmentHistory && (segmentHistory.realTime || segmentHistory.gameTime) && Number(a.id) >= minReachedSegmentID;
    }).length;



    

  const completionRate = (attemptsSegment / attemptsReachedSegment) * 100;
  const resetRate = 100 - completionRate;
//console.log(attemptsSegment + 'out of' + attemptsReachedSegment);
  // Calculate Consistency Rating
  const times = attempts
    .map(a => {
      const segmentHistory = segments[segmentIndex].segmentHistory.find(h => Number(h.id) === Number(a.id));
      return segmentHistory ? parseTime(segmentHistory.realTime || segmentHistory.gameTime) : null;
    })
    .filter(time => time !== null);
  const meanTime = times.reduce((a, b) => a + b, 0) / times.length;
  const variance = times.reduce((a, b) => a + Math.pow(b - meanTime, 2), 0) / times.length;
  const consistencyRating = Math.sqrt(variance);

  // Calculate Improvement Rate
  const improvements = times.map(time => pbTime - time).filter(improvement => improvement > 0);
  const improvementRate = improvements.length ? improvements.reduce((a, b) => a + b, 0) / improvements.length : 0;

  // Calculate Recent Success Rate
  const recentAttempts = attempts.slice(-20);
  const recentSuccesses = recentAttempts.filter(a => {
    const segmentHistory = segments[segmentIndex].segmentHistory.find(h => Number(h.id) === Number(a.id));
    return segmentHistory && (segmentHistory.realTime || segmentHistory.gameTime);
  }).length;
  const recentSuccessRate = (recentSuccesses / Math.min(recentAttempts.length, 20)) * 100;

  // Prepare data for the chart
  const recentTimes = recentAttempts.map((a, index) => {
    const segmentHistory = segments[segmentIndex].segmentHistory.find(h => Number(h.id) === Number(a.id));
    return {
      attempt: index + 1,
      time: segmentHistory ? parseTime(segmentHistory.realTime || segmentHistory.gameTime) : 0
    };
  });


//   // Calculate Average Reset Time
//   const resetTimes = attempts.filter(a => {
//     const currentSegmentHistory = segments[segmentIndex].segmentHistory.find(h => Number(h.id) === Number(a.id));
//     const previousSegmentHistory = segmentIndex > 0 ? segments[segmentIndex - 1].segmentHistory.find(h => Number(h.id) === Number(a.id)) : null;

//     if (!currentSegmentHistory && previousSegmentHistory) {
//       return true;
//     }
//     return false;
//   }).map(a => {

//     const endTime = new Date(a.endedDate).getTime();
//     const startTime = new Date(a.startedDate).getTime();
//     console.log(formatTime(endTime - startTime));
//     return endTime - startTime;
//   });
//   console.log(resetTimes);

  // Calculate Average Reset Time into the Split
  const previousSegmentTotalTime = segments.slice(0, segmentIndex)
    .reduce((acc, seg) => acc + (averageSegmentTimes[seg.name] || 0), 0);

  const resetTimesIntoSegment = attempts.filter(a => {
    const currentSegmentHistory = segments[segmentIndex].segmentHistory.find(h => Number(h.id) === Number(a.id));
    const previousSegmentHistory = segmentIndex > 0 ? segments[segmentIndex - 1].segmentHistory.find(h => Number(h.id) === Number(a.id)) : null;

    if (!currentSegmentHistory && previousSegmentHistory) {
      return true;
    }
    return false;
  }).map(a => {
    const endTime = new Date(a.endedDate).getTime();
    const startTime = new Date(a.startedDate).getTime();
    const totalRunTime = endTime - startTime;
    return totalRunTime - previousSegmentTotalTime;
  });

  const thresholdTime = avgTime * 1.2;
  const filteredResetTimes = resetTimesIntoSegment.filter(time => time <= thresholdTime);

  const averageResetTimeIntoSegment = filteredResetTimes.length ? filteredResetTimes.reduce((a, b) => a + b, 0) / filteredResetTimes.length : 0;


//   // Prepare data for the histogram
//   const binSize = 10000; // Bin size in milliseconds (10 seconds)
//   const histogramData = filteredResetTimes.reduce((acc, time) => {
//     const bin = Math.floor(time / binSize) * binSize;
//     const existingBin = acc.find(item => item.bin === bin);
//     if (existingBin) {
//       existingBin.count += 1;
//     } else {
//       acc.push({ bin, count: 1 });
//     }
//     return acc;
//   }, []).sort((a, b) => a.bin - b.bin);


//       const averageResetTime = resetTimes.length ? resetTimes.reduce((a, b) => a + b, 0) / resetTimes.length : 0;
    


  // Tooltip messages
  const tooltips = {
    pbTime: "Personal Best (PB) time for this segment.",
    bestTime: "Best recorded time for this segment.",
    avgTime: "Average time for this segment across all attempts.",
    timeSavePotential: "Potential time save compared to the PB.",
    resetRate: "How often you reset this segment once you got to it.",
    completionRate: "How often you finish this segment once you got to it.",
    consistencyRating: "Standard deviation of segment times. Lower is better.",
    improvementRate: "Average improvement in time compared to the PB.",
    recentSuccessRate: "Success rate for this segment in the last 20 attempts."
  };

  const renderTooltip = (message) => (
    <Tooltip>{message}</Tooltip>
  );

  return (
    <div className="card p-3 shadow-sm">
      <h5 className="card-title">{segment.name}</h5>

      <div className="d-flex justify-content-between mb-2">
        <OverlayTrigger placement="top" overlay={renderTooltip(tooltips.pbTime)}>
          <span>PB Time:</span>
        </OverlayTrigger>
        <span>{formatTime(pbTime)}</span>
      </div>

      <div className="d-flex justify-content-between mb-2">
        <OverlayTrigger placement="top" overlay={renderTooltip(tooltips.bestTime)}>
          <span>Best Time:</span>
        </OverlayTrigger>
        <span>{formatTime(bestTime)}</span>
      </div>

      <div className="d-flex justify-content-between mb-2">
        <OverlayTrigger placement="top" overlay={renderTooltip(tooltips.avgTime)}>
          <span>Average Time:</span>
        </OverlayTrigger>
        <span>{formatTime(avgTime)}</span>
      </div>

      <div className="d-flex justify-content-between mb-2">
        <OverlayTrigger placement="top" overlay={renderTooltip(tooltips.timeSavePotential)}>
          <span>Time Save Potential:</span>
        </OverlayTrigger>
        <span className={getDiffColor(bestTime - pbTime)}>{formatDiff(bestTime - pbTime)}</span>
      </div>

      <div className="d-flex justify-content-between mb-2">
        <OverlayTrigger placement="top" overlay={renderTooltip(tooltips.resetRate)}>
          <span>Reset Rate:</span>
        </OverlayTrigger>
        <span>{resetRate.toFixed(2)}%</span>
      </div>

      <div className="d-flex justify-content-between mb-2">
        <OverlayTrigger placement="top" overlay={renderTooltip(tooltips.completionRate)}>
          <span>Completion Rate:</span>
        </OverlayTrigger>
        <span>{completionRate.toFixed(2)}%</span>
      </div>

      {/* <div className="d-flex justify-content-between mb-2">
        <OverlayTrigger placement="top" overlay={renderTooltip(tooltips.consistencyRating)}>
          <span>Consistency Rating:</span>
        </OverlayTrigger>
        <span>{formatTime(consistencyRating)}</span>
      </div> */}

      {/* <div className="d-flex justify-content-between mb-2">
        <OverlayTrigger placement="top" overlay={renderTooltip(tooltips.improvementRate)}>
          <span>Improvement Rate:</span>
        </OverlayTrigger>
        <span>{formatDiff(improvementRate)}</span>
      </div> */}

      {/* <div className="d-flex justify-content-between mb-2">
        <OverlayTrigger placement="top" overlay={renderTooltip(tooltips.recentSuccessRate)}>
          <span>Recent Success Rate:</span>
        </OverlayTrigger>
        <span>{recentSuccessRate.toFixed(2)}%</span>
      </div> */}

      {/* <div className="d-flex justify-content-between">
        <OverlayTrigger placement="top" overlay={renderTooltip("Total attempts that finished this segment.")}>
          <span>Attempts Finished:</span>
        </OverlayTrigger>
        <span>{attemptsReachedSegment} / {totalAttempts}</span>
      </div> */}

      {/* Chart for recent times */}
      <div className="mt-4">
        <h6>Recent Segment Times</h6>
        <ResponsiveContainer width="100%" height={200}>
          <LineChart data={recentTimes}>
            <XAxis dataKey="attempt" />
            <YAxis tickFormatter={formatTime} />
            <RechartTooltip formatter={formatTime} />
            <Line type="monotone" dataKey="time" stroke="#8884d8" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      {/* <ResponsiveContainer width="100%" height={200}>
        <BarChart data={histogramData}>
          <XAxis dataKey="bin" tickFormatter={(tick) => formatTime(tick)} />
          <YAxis />
          <RechartTooltip />
          <Bar dataKey="count">
            {
              histogramData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.count > 1 ? '#E6733B' : '#E6A822'} />
              ))
            }
          </Bar>
        </BarChart>
      </ResponsiveContainer> */}
    </div>
  );
};

export default SegmentDetail;
