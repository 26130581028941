export const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const ms = milliseconds % 1000;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
  
    const formattedTime = `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    
    return hours > 0 ? `${hours.toString().padStart(2, '0')}:${formattedTime}` : formattedTime;
  };

  export const formatDiff = (diff) => {
    const sign = diff >= 0 ? '+' : '-';
    return `${sign}${formatTime(Math.abs(diff))}`;
  };

  export const getDiffColor = (diff) => {
    return diff >= 0 ? 'text-danger' : 'text-success'; // Red for positive diff, Green for negative diff
  };

  export const COLORS = ['#0073D1', '#00A88F', '#E6A822', '#E6733B', '#7369C0', '#73B28B', '#E6AD4C', '#75B6CC'];

  // const parseTime = (timeString) => {
//   if (!timeString) return 0;
//   const [hours, minutes, seconds] = timeString.split(':').map(Number);
//   return hours * 3600 + minutes * 60 + seconds;
// };

export const parseTime = (timeString) => {
    if (!timeString) return 0;
  
    // Split time string into main part and milliseconds
    const [mainTime, millisecondsPart] = timeString.split('.');
    const milliseconds = millisecondsPart ? parseInt(millisecondsPart.slice(0, 3)) : 0; // Take only first three digits for milliseconds
  
    // Split main part into hours, minutes, and seconds
    const [hours, minutes, seconds] = mainTime.split(':').map(Number);
  
    // Convert hours, minutes, seconds to total seconds and add milliseconds part
    return (hours * 3600 + minutes * 60 + seconds) * 1000 + milliseconds;
  };

  export const formatYouTubeTimestamp = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    if (hours > 0) {
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else {
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  };