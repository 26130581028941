import React, { useEffect, useRef, useState } from 'react';
import { Diff } from 'lucide-react';

const formatTime = (ms) => {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  return `${hours.toString().padStart(2, '0')}:${(minutes % 60).toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;
};

const formatTimeDifference = (ms) => {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  return `+${minutes.toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;
};

const truncateToMilliseconds = (time) => {
  return Math.floor(time / 1000) * 1000;
};

const LeaderboardRow = React.memo(({ rank, time, name, country, isUser, timeDifference, userRef, rowHeight, videoLink, comparisonAvailable, onComparisonClick, runId }) => (
  <tr className={isUser ? "table-primary" : ""} ref={isUser ? userRef : null} style={{ height: rowHeight, cursor: 'pointer' }}>
    <td>{rank}</td>
    <td>{formatTime(time)}</td>
    <td>
    <div className="d-flex align-items-center" onClick={() => window.open(videoLink, '_blank')}>
        {country && country !== 'Unknown' && <img src={`/flags/${country.replace('/', '-')}.png`} alt={country} className="me-2" style={{ width: '24px', height: '16px' }} />}
        {isUser ? "You" : name}
      </div>
    </td>
    <td>{isUser ? "-" : formatTimeDifference(timeDifference)}</td>
    <td>
    {comparisonAvailable === 1 && (
  <Diff
    title="Comparison available"
    style={{ color: 'green', marginLeft: '10px' }}
    onClick={() => onComparisonClick(runId) }
  />
)}
    </td>
  </tr>
));

const Leaderboard = ({ gameInfo, bestTime, loadComparison, height = '120px' }) => {

  const userRef = useRef(null);
  const containerRef = useRef(null);
  const [rowHeight, setRowHeight] = useState(`${parseInt(height) / 3}px`);

  const getDisplayRuns = React.useMemo(() => {
    const runs = [...gameInfo.runs].sort((a, b) => a.time_primary - b.time_primary);

    // const userRank = runs.findIndex(run => bestTime <= run.time_primary) + 1;
    // const displayRuns = runs.filter(run => run.time_primary < bestTime);
    // Truncate bestTime
const truncatedBestTime = truncateToMilliseconds(bestTime);
const userRank = runs.findIndex(run => truncateToMilliseconds(run.time_primary) <= truncatedBestTime) + 1;
const displayRuns = runs.filter(run => truncateToMilliseconds(run.time_primary) < truncatedBestTime);

    displayRuns.push({ rank: userRank, time_primary: bestTime, isUser: true });
    displayRuns.sort((a, b) => a.time_primary - b.time_primary);
    for (let i = 0; i < displayRuns.length; i++) {
      displayRuns[i].rank = i + 1;
      displayRuns[i].timeDifference = Math.abs(bestTime - displayRuns[i].time_primary);
    }
    return displayRuns;
  }, [gameInfo.runs, bestTime]);

  useEffect(() => {
    if (userRef.current) {
      userRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [userRef]);

  useEffect(() => {
    const container = containerRef.current;
    let scrollTimeout;

    const handleScroll = (event) => {
      event.preventDefault();
      

        const scrollDirection = event.deltaY > 0 ? 120 : -120;

        container.scrollBy({
          top: scrollDirection,
          behavior: 'instant'
        });

    };

    if (container) {
      container.addEventListener('wheel', handleScroll, { passive: false });
    }

    return () => {
      if (container) {
        container.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);

  return (
    <div ref={containerRef} style={{ height, overflowY: 'auto' }}>
      <table className="table table-responsive table-borderless mb-0">
        <tbody>
          {getDisplayRuns.map((run) => (
            <LeaderboardRow
              key={`${run.rank}-${run.isUser ? 'user' : 'leaderboard'}`}
              rank={run.rank}
              time={run.time_primary}
              name={run.player_name}
              country={run.player_country}
              isUser={run.isUser}
              timeDifference={run.timeDifference}
              userRef={userRef}
              rowHeight={rowHeight}
              videoLink={run.video_link}
              comparisonAvailable={run.comparison_available}
              onComparisonClick={ (runId) => loadComparison(runId) }
              runId={run.id}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;
